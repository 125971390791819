import { axiosBaseQuery } from "@api/network";
import { RegionsMap } from "@models/Companies/companies.model";
import {
  DocsResponse,
  FaqResponse,
} from "@models/Faq&Documents/Faq&Documents.model";
import { GlossariesMap } from "@models/Glossary/Glossary.model";
import { Preference } from "@models/Preferences/Preferences.models";
import { createApi } from "@reduxjs/toolkit/query/react";
import userManager from "@services/Auth";
import { base64ToFile } from "@utils/base64ToFile";
import { centralInstitution } from "@utils/constants/centralInstitution";
import { buildGlossariesMapFromList } from "@utils/function/buildGlossariesMapFromList";
import { buildRegionsMapFromList } from "@utils/function/buildRegionsMapFromList";
import { parseKpi } from "@utils/function/parseKpi";
import saveFile from "file-saver";
import {
  DownloadCSVRequest,
  DownloadCSVResponse,
  DownloadFileResponse,
  GetAllPreferencesResponse,
  // GetKPIsListApiResponse,
  GetAllRegionsApiResponse,
  GetGlossariesApiResponse,
  GetGlossaryApiRequest,
  GetGlossaryApiResponse,
  GetKPIsApiRequest,
  GetKPIsApiResponse,
  GetProfileApiResponse,
  GetProfileResponseDTO,
  GetQuarterReporRequest,
  GetRegionsByQuarterResponse,
  GetTermsResponse,
  GetTermsWithoutIndexResponse,
  SavePreferenceRequest,
  SavePreferenceResponse,
} from "./orchestrator.models";

export const orchestratorApi = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({
    // This env var should be mandatory
    baseUrl: process.env.REACT_APP_SERVER_HOST!,
    requestInterceptors: [
      async (request) => {
        const user = await userManager.getUser();
        if (process.env.NODE_ENV === "development") {
          request.headers["OP-FiscalCode"] = user?.profile.cf;
        } else {
          request.headers["Authorization"] = `Bearer ${user?.access_token}`;
        }
        return request;
      },
    ],
  }),
  tagTypes: ["preference"],
  endpoints: (build) => ({
    getProfile: build.query<GetProfileApiResponse, void>({
      query: () => ({
        method: "GET",
        url: "/profile",
      }),
      transformResponse: (response: GetProfileResponseDTO) => {
        return {
          ...response,
          hasCentralInstitution: response.roles.some(
            (role) => role.region === centralInstitution
          ),
        };
      },
    }),
    getGlossaryById: build.query<GetGlossaryApiResponse, GetGlossaryApiRequest>(
      {
        query: ({ glossaryId }) => ({
          method: "GET",
          url: `/glossary/${glossaryId}`,
        }),
      }
    ),
    getGlossaries: build.query<GlossariesMap, void>({
      query: () => ({
        method: "GET",
        url: `/glossary`,
      }),
      transformResponse: (data: GetGlossariesApiResponse): GlossariesMap => {
        return buildGlossariesMapFromList(data.glossaries);
      },
    }),
    getFaq: build.query<FaqResponse, void>({
      query: () => ({
        method: "GET",
        url: `/faqs`,
      }),
    }),
    getDocs: build.query<DocsResponse, void>({
      query: () => ({
        method: "GET",
        url: `/docs`,
      }),
    }),
    getKPIs: build.query<GetKPIsApiResponse, GetKPIsApiRequest>({
      query: ({ KPIs, period, sampling, region, company, structure }) => {
        return {
          method: "GET",
          url: "/kpi/ranged",
          params: {
            kpis: KPIs,
            period,
            sampling,
            region,
            company,
            structure,
          },
        };
      },
      transformResponse: parseKpi,
    }),
    getAllRegions: build.query<RegionsMap, void>({
      query: () => ({
        method: "GET",
        url: `/configuration/companies`,
      }),
      transformResponse: (response: GetAllRegionsApiResponse) =>
        buildRegionsMapFromList(response.regions),
    }),
    downloadCSV: build.mutation<DownloadCSVResponse, DownloadCSVRequest>({
      query: ({ glossaryId, periodId, sampling, regions, exportType }) => ({
        method: "POST",
        url: "/report/ranged",
        data: {
          glossaryId,
          periodId,
          sampling,
          regions,
          exportType,
        },
        responseHandler: (response) => {
          if (response.status === 200) {
            const csvData = response.data as Partial<DownloadCSVResponse>;

            if (
              typeof csvData.csvReportB64 === "string" &&
              typeof csvData.filename === "string"
            ) {
              const file = base64ToFile(
                csvData.csvReportB64,
                csvData.filename ?? "csv"
              );

              saveFile(file);
            }
            return Promise.resolve(csvData);
          }
          return Promise.resolve({ detail: response.statusText });
        },
        cache: "no-cache",
      }),
    }),
    getRegionsByQuarter: build.query<GetRegionsByQuarterResponse, void>({
      query: () => ({
        method: "GET",
        url: "/configuration/report/regions",
      }),
    }),
    getQuarterReport: build.query<DownloadFileResponse, GetQuarterReporRequest>(
      {
        query: ({ region }) => ({
          method: "GET",
          url: "report/download",
          params: {
            region,
          },
        }),
      }
    ),
    getAllPreferences: build.query<Preference[], void>({
      query: () => ({
        method: "GET",
        url: `/preferences`,
      }),
      transformResponse: (data: GetAllPreferencesResponse): Preference[] => {
        return data.preferences;
      },
      providesTags: ["preference"],
    }),
    savePreference: build.mutation<
      SavePreferenceResponse,
      SavePreferenceRequest
    >({
      query: ({
        region,
        company,
        structure,
        glossaryId,
        sampling,
        period,
      }) => ({
        method: "POST",
        url: "/preferences",
        data: {
          region,
          company,
          structure,
          glossaryId,
          sampling,
          period,
        },
      }),
      invalidatesTags: ["preference"],
    }),
    deletePreference: build.mutation<
      SavePreferenceResponse,
      SavePreferenceRequest
    >({
      invalidatesTags: ["preference"],
      query: ({
        region,
        company,
        structure,
        glossaryId,
        sampling,
        period,
      }) => ({
        method: "PATCH",
        url: "/preferences",
        headers: {
          "Content-Type": "application/patch+json",
        },
        data: {
          currentPreference: {
            region: region,
            company: company,
            structure: structure,
            glossaryId: glossaryId,
            sampling: sampling,
            period: period,
          },
          action: "DELETE",
        },
      }),
    }),
    getAllTerms: build.query<GetTermsResponse, void>({
      query: () => ({ method: "GET", url: "terms" }),
    }),
    getTermsByIndex: build.query<
      GetTermsWithoutIndexResponse,
      { index: number; terminId: number }
    >({
      query: ({ index, terminId }) => ({
        method: "GET",
        url: `/terms/${index}/ids/${terminId}`,
      }),
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useLazyGetGlossaryByIdQuery,
  useGetGlossaryByIdQuery,
  useLazyGetGlossariesQuery,
  useGetGlossariesQuery,
  useGetKPIsQuery,
  useLazyGetKPIsQuery,
  useLazyGetAllRegionsQuery,
  useGetAllRegionsQuery,
  useDownloadCSVMutation,
  useLazyGetQuarterReportQuery,
  useGetRegionsByQuarterQuery,
  useLazyGetAllPreferencesQuery,
  useGetAllPreferencesQuery,
  useSavePreferenceMutation,
  useDeletePreferenceMutation,
  useGetAllTermsQuery,
  useLazyGetRegionsByQuarterQuery,
  useLazyGetTermsByIndexQuery,
  useGetFaqQuery,
  useLazyGetFaqQuery,
  useGetDocsQuery,
  useLazyGetDocsQuery,
} = orchestratorApi;
